/** Includes General Common Classes*/
 *, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
 ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.pointer-events-none {
  pointer-events: none;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  inset: 0px;
}
.-top-\[6px\] {
  top: -6px;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-10 {
  bottom: 2.5rem;
}
.left-0 {
  left: 0px;
}
.left-\[-2px\] {
  left: -2px;
}
.left-\[5\%\] {
  left: 5%;
}
.left-\[50\%\] {
  left: 50%;
}
.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}
.top-\[-12px\] {
  top: -12px;
}
.top-\[102\%\] {
  top: 102%;
}
.top-\[113px\] {
  top: 113px;
}
.top-\[12px\] {
  top: 12px;
}
.top-\[50\%\] {
  top: 50%;
}
.top-\[60px\] {
  top: 60px;
}
.z-10 {
  z-index: 10;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.m-0 {
  margin: 0px;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.\!mt-\[24px\] {
  margin-top: 24px !important;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.ms-0 {
  margin-inline-start: 0px;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-\[40px\] {
  margin-top: 40px;
}
.mt-\[42px\] {
  margin-top: 42px;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.aspect-\[4\/3\] {
  aspect-ratio: 4/3;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.h-0\.5 {
  height: 0.125rem;
}
.h-11 {
  height: 2.75rem;
}
.h-20 {
  height: 5rem;
}
.h-4 {
  height: 1rem;
}
.h-4\.5 {
  height: 18px;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-\[130px\] {
  height: 130px;
}
.h-\[156px\] {
  height: 156px;
}
.h-\[18px\] {
  height: 18px;
}
.h-\[1px\] {
  height: 1px;
}
.h-\[24px\] {
  height: 24px;
}
.h-\[28px\] {
  height: 28px;
}
.h-\[328px\] {
  height: 328px;
}
.h-\[48px\] {
  height: 48px;
}
.h-\[56px\] {
  height: 56px;
}
.h-\[96px\] {
  height: 96px;
}
.h-full {
  height: 100%;
}
.h-px {
  height: 1px;
}
.max-h-\[476px\] {
  max-height: 476px;
}
.max-h-\[700px\] {
  max-height: 700px;
}
.min-h-\[20px\] {
  min-height: 20px;
}
.min-h-\[32px\] {
  min-height: 32px;
}
.min-h-\[calc\(100vh-61px\)\] {
  min-height: calc(100vh - 61px);
}
.\!w-full {
  width: 100% !important;
}
.w-1\/2 {
  width: 50%;
}
.w-11 {
  width: 2.75rem;
}
.w-24 {
  width: 6rem;
}
.w-4 {
  width: 1rem;
}
.w-4\.5 {
  width: 18px;
}
.w-5 {
  width: 1.25rem;
}
.w-56 {
  width: 14rem;
}
.w-6 {
  width: 1.5rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-\[156px\] {
  width: 156px;
}
.w-\[18px\] {
  width: 18px;
}
.w-\[250px\] {
  width: 250px;
}
.w-\[280px\] {
  width: 280px;
}
.w-\[40\%\] {
  width: 40%;
}
.w-\[74px\] {
  width: 74px;
}
.w-\[90\%\] {
  width: 90%;
}
.w-\[96px\] {
  width: 96px;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.w-px {
  width: 1px;
}
.min-w-11 {
  min-width: 2.75rem;
}
.min-w-14 {
  min-width: 3.5rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-9 {
  min-width: 2.25rem;
}
.min-w-\[112px\] {
  min-width: 112px;
}
.min-w-\[122px\] {
  min-width: 122px;
}
.min-w-\[166px\] {
  min-width: 166px;
}
.min-w-\[1px\] {
  min-width: 1px;
}
.min-w-\[298px\] {
  min-width: 298px;
}
.min-w-\[50px\] {
  min-width: 50px;
}
.\!max-w-\[1150px\] {
  max-width: 1150px !important;
}
.\!max-w-full {
  max-width: 100% !important;
}
.max-w-5xl {
  max-width: 64rem;
}
.max-w-7xl {
  max-width: 80rem;
}
.max-w-\[1330px\] {
  max-width: 1330px;
}
.max-w-\[145px\] {
  max-width: 145px;
}
.max-w-\[228px\] {
  max-width: 228px;
}
.max-w-\[298px\] {
  max-width: 298px;
}
.max-w-\[90\%\] {
  max-width: 90%;
}
.max-w-full {
  max-width: 100%;
}
.shrink-0 {
  flex-shrink: 0;
}
.grow {
  flex-grow: 1;
}
.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.resize {
  resize: both;
}
.scroll-mt-\[170px\] {
  scroll-margin-top: 170px;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.content-center {
  align-content: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.gap-0\.5 {
  gap: 0.125rem;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-\[2px\] {
  gap: 2px;
}
.gap-\[4px\] {
  gap: 4px;
}
.gap-\[5px\] {
  gap: 5px;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.self-baseline {
  align-self: baseline;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre {
  white-space: pre;
}
.text-balance {
  text-wrap: balance;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-\[32px\] {
  border-radius: 32px;
}
.rounded-\[5px\] {
  border-radius: 5px;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-inherit {
  border-radius: inherit;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-\[\#3C56D4\] {
  --tw-border-opacity: 1;
  border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}
.border-\[\#E1E8F2\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 232 242 / var(--tw-border-opacity, 1));
}
.border-\[\#E9E9E9\] {
  --tw-border-opacity: 1;
  border-color: rgb(233 233 233 / var(--tw-border-opacity, 1));
}
.border-\[\#f5f7fa\] {
  --tw-border-opacity: 1;
  border-color: rgb(245 247 250 / var(--tw-border-opacity, 1));
}
.border-current {
  border-color: currentColor;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.border-gray-azureish {
  --tw-border-opacity: 1;
  border-color: rgb(225 232 242 / var(--tw-border-opacity, 1));
}
.border-gray-gainsboro {
  --tw-border-opacity: 1;
  border-color: rgb(214 223 233 / var(--tw-border-opacity, 1));
}
.border-gray-text {
  --tw-border-opacity: 1;
  border-color: rgb(72 72 72 / var(--tw-border-opacity, 1));
}
.border-neutral-60 {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity, 1));
}
.border-neutral-70 {
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}
.border-transparent {
  border-color: transparent;
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-b-gray-gainsboro {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(214 223 233 / var(--tw-border-opacity, 1));
}
.bg-\[\#00B362\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 179 98 / var(--tw-bg-opacity, 1));
}
.bg-\[\#3C56D4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.bg-\[\#4a4d51\] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 77 81 / var(--tw-bg-opacity, 1));
}
.bg-\[\#6f6e6e\] {
  --tw-bg-opacity: 1;
  background-color: rgb(111 110 110 / var(--tw-bg-opacity, 1));
}
.bg-\[\#E1E8F2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 232 242 / var(--tw-bg-opacity, 1));
}
.bg-\[\#F1F6FC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 246 252 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FF6029\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 96 41 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#eff6ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}
.bg-blue-water {
  --tw-bg-opacity: 1;
  background-color: rgb(204 229 255 / var(--tw-bg-opacity, 1));
}
.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(241 16 16 / var(--tw-bg-opacity, 1));
}
.bg-danger-120 {
  --tw-bg-opacity: 1;
  background-color: rgb(179 9 9 / var(--tw-bg-opacity, 1));
}
.bg-danger-old {
  --tw-bg-opacity: 1;
  background-color: rgb(205 33 38 / var(--tw-bg-opacity, 1));
}
.bg-gray-alice {
  --tw-bg-opacity: 1;
  background-color: rgb(243 247 251 / var(--tw-bg-opacity, 1));
}
.bg-gray-azureish {
  --tw-bg-opacity: 1;
  background-color: rgb(225 232 242 / var(--tw-bg-opacity, 1));
}
.bg-gray-gainsboro {
  --tw-bg-opacity: 1;
  background-color: rgb(214 223 233 / var(--tw-bg-opacity, 1));
}
.bg-neutral-0 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}
.bg-neutral-70 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(0 179 98 / var(--tw-bg-opacity, 1));
}
.bg-success-120 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 132 71 / var(--tw-bg-opacity, 1));
}
.bg-transparent {
  background-color: transparent;
}
.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(255 185 0 / var(--tw-bg-opacity, 1));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-white-ghost {
  --tw-bg-opacity: 1;
  background-color: rgb(248 251 254 / var(--tw-bg-opacity, 1));
}
.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-\[10px\] {
  padding: 10px;
}
.p-\[24px\] {
  padding: 24px;
}
.p-\[2px\] {
  padding: 2px;
}
.p-\[3px\] {
  padding: 3px;
}
.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.\!px-\[20px\] {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px;
}
.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.\!pb-\[0px\] {
  padding-bottom: 0px !important;
}
.\!pb-\[5px\] {
  padding-bottom: 5px !important;
}
.\!pt-\[5px\] {
  padding-top: 5px !important;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-\[28px\] {
  padding-bottom: 28px;
}
.pb-\[3px\] {
  padding-bottom: 3px;
}
.pb-\[40px\] {
  padding-bottom: 40px;
}
.pb-\[80px\] {
  padding-bottom: 80px;
}
.pe-1 {
  padding-inline-end: 0.25rem;
}
.ps-1 {
  padding-inline-start: 0.25rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-\[8px\] {
  padding-top: 8px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.font {
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.font-inter {
  font-family: "Inter", sans-serif;
}
.font-plex-arabic {
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-\[13px\] {
  font-size: 13px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[8px\] {
  font-size: 8px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-\[10px\] {
  line-height: 10px;
}
.leading-\[12px\] {
  line-height: 12px;
}
.leading-\[16px\] {
  line-height: 16px;
}
.leading-\[24px\] {
  line-height: 24px;
}
.leading-\[28px\] {
  line-height: 28px;
}
.leading-\[32px\] {
  line-height: 32px;
}
.text-\[\#00B362\] {
  --tw-text-opacity: 1;
  color: rgb(0 179 98 / var(--tw-text-opacity, 1));
}
.text-\[\#0E5BC0\] {
  --tw-text-opacity: 1;
  color: rgb(14 91 192 / var(--tw-text-opacity, 1));
}
.text-\[\#154F9C\] {
  --tw-text-opacity: 1;
  color: rgb(21 79 156 / var(--tw-text-opacity, 1));
}
.text-\[\#154f9c\] {
  --tw-text-opacity: 1;
  color: rgb(21 79 156 / var(--tw-text-opacity, 1));
}
.text-\[\#3C56D4\] {
  --tw-text-opacity: 1;
  color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}
.text-\[\#484848\] {
  --tw-text-opacity: 1;
  color: rgb(72 72 72 / var(--tw-text-opacity, 1));
}
.text-\[\#4B5A6B\] {
  --tw-text-opacity: 1;
  color: rgb(75 90 107 / var(--tw-text-opacity, 1));
}
.text-\[\#697A8F\] {
  --tw-text-opacity: 1;
  color: rgb(105 122 143 / var(--tw-text-opacity, 1));
}
.text-\[\#C0C0C0\] {
  --tw-text-opacity: 1;
  color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
.text-blue-old {
  --tw-text-opacity: 1;
  color: rgb(21 79 156 / var(--tw-text-opacity, 1));
}
.text-danger {
  --tw-text-opacity: 1;
  color: rgb(241 16 16 / var(--tw-text-opacity, 1));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.text-gray-causeway {
  --tw-text-opacity: 1;
  color: rgb(108 122 141 / var(--tw-text-opacity, 1));
}
.text-gray-text {
  --tw-text-opacity: 1;
  color: rgb(72 72 72 / var(--tw-text-opacity, 1));
}
.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity, 1));
}
.text-neutral-40 {
  --tw-text-opacity: 1;
  color: rgb(78 89 103 / var(--tw-text-opacity, 1));
}
.text-neutral-70 {
  --tw-text-opacity: 1;
  color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
.text-neutral-90 {
  --tw-text-opacity: 1;
  color: rgb(56 56 56 / var(--tw-text-opacity, 1));
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}
.text-primary-120 {
  --tw-text-opacity: 1;
  color: rgb(7 65 142 / var(--tw-text-opacity, 1));
}
.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(254 86 28 / var(--tw-text-opacity, 1));
}
.text-success {
  --tw-text-opacity: 1;
  color: rgb(0 179 98 / var(--tw-text-opacity, 1));
}
.text-warning {
  --tw-text-opacity: 1;
  color: rgb(255 185 0 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.underline {
  text-decoration-line: underline;
}
.line-through {
  text-decoration-line: line-through;
}
.decoration-solid {
  text-decoration-style: solid;
}
.decoration-1 {
  text-decoration-thickness: 1px;
}
.underline-offset-4 {
  text-underline-offset: 4px;
}
.opacity-\[0\.5993\] {
  opacity: 0.5993;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_0_10px_rgba\(188\2c 188\2c 188\2c 0\.4\)\] {
  --tw-shadow: 0 0 10px rgba(188,188,188,0.4);
  --tw-shadow-colored: 0 0 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-card {
  --tw-shadow: 0 0 12px 0 #0000001A;
  --tw-shadow-colored: 0 0 12px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-500 {
  transition-duration: 500ms;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.\[border\:none\!important\] {
  border: none !important;
}

strong {
  font-weight: 700;
}

.blueBtn {
  background-color: var(--blueColor) !important;
  transition: 0.2s;
  border: solid 1px var(--blueColor) !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.blueBtn:hover {
  background-color: #104285 !important;
}

.blueBtn:active {
  background-color: #113c76 !important;
}

.greenBtn {
  background-color: #00b362 !important;
  transition: 0.2s;
  border: solid 1px #00b362 !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.greenBtn:hover {
  background-color: #049453 !important;
}

.greenBtn:active {
  background-color: #037b45 !important;
}

.greenBtn:disabled {
  opacity: 0.5;
}

.greenGrd {
  font-weight: normal;
  color: #00b362;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.redBtn22 {
  background-color: #ec6234;
  transition: 0.2s;
  border: solid 1px #ec6234;
  box-sizing: border-box;
  color: #ffffff;
}

.redBtn22:hover {
  background-color: #d65428;
}

.redBtn22:active {
  background-color: #cc4c21;
}
.blueTextBtn {
  background-color: #e2eaf4 !important;
  transition: 0.2s;
  border: solid 1px #e2eaf4 !important;
  box-sizing: border-box;
  color: #3f5776 !important;
}
.blueTextBtn:hover,
.blueTextBtn:active {
  background-color: #154f9c !important;
  color: #fff !important;
}

.whtBtn {
  background-color: transparent !important;
  transition: 0.2s;
  color: var(--blueColor) !important;
  border: solid 1px var(--blueColor) !important;
  box-sizing: border-box;
}

.whtBtn:hover {
  background-color: var(--blueColor) !important;
  color: #ffffff !important;
}

.whtBtn:active {
  background-color: #104285 !important;
  color: #ffffff !important;
}

.gryBtn {
  background-color: transparent !important;
  transition: 0.2s;
  color: #6c7a8d !important;
  border: solid 1px #6c7a8d !important;
  box-sizing: border-box;
}

.gryBtn:hover {
  background-color: #6c7a8d !important;
  color: #ffffff !important;
}

.gryBtn:active {
  background-color: #6c7a8d !important;
  color: #ffffff !important;
}

.big {
  color: #154f9c;
  font-size: 30px;
  line-height: 56px;
  font-weight: bold;
  position: relative;
  padding-bottom: 30px;
  text-align: center;
  word-wrap: break-word;
}

.big::after {
  /* content: ""; */
  width: 104px;
  height: 5px;
  background-color: #ec6234;
  position: absolute;
  left: calc(50% - 104px / 2);
  bottom: 0px;
}

.whtBtn {
  background-color: transparent !important;
  transition: 0.2s;
  color: var(--blueColor) !important;
  border: solid 1px var(--blueColor) !important;
}

.container {
  max-width: 2000px;
  width: 90%;
  margin: 0 auto;
}

.siteHeader > .innerHdr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body[dir="ltr"] .siteHeader > .innerHdr > ul > li:not(:first-child) {
  margin-left: 30px;
}

body[dir="ltr"],
body[dir="ltr"] * {
  font-family: Lato !important;
}

.webview:not(.IOS) [class*="backtnDT"],
.webview:not(.IOS) [class*="mobileBackBlueArr"] {
  display: none !important;
}


.webview [class*="InfoDetailsComponent-module__linkLikeName"] {
  /* pointer-events: none !important; */
}

.webview [class*="TrackingNoResult-module__container"] > p {
  display: none !important;
}

.webview [class*="TrackingNoResult-module__btn"]
/* ,.webview svg[class*="ArrowType2SVG"], */
/* .webview [class*="AfterSubmitComp-module__wrapper"] */ {
  display: none !important;
}

.closeWebView,
.webviewHEading,
.webview [class*="FilePreview-module__whtButton"] {
  display: none !important;
}

.webview .closeWebView {
  display: flex !important;
  position: fixed;
  z-index: 99999;
  left: 0;
  background: #154f9c;
  top: 83px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
  padding: 5px 5px;
}

.webview div[class*="BottomSheetPopup-module__genralPopUpContent"] {
  height: 600px;
}

.webview div[class*="SharedPopUp-module__genralPopUpContent"] {
  height: 600px;
}

.webview .postViewContainer {
  padding-top: 40px;
}

body[dir="rtl"] .webview [class*="MobileHeader1"] [class*="ArrowType2SVG"] {
  transform: rotate(180deg);
}

.webview .webviewHEading {
  position: fixed;
  top: 0;
  z-index: 9;
  right: 0;
  display: block !important;
  width: 60px;
  height: 50px;
  background-color: #ffffff;
}

body[dir="rtl"] .webview .webviewHEading {
  right: 0;
}

body[dir="ltr"] .webview .webviewHEading {
  left: 0;
}

.webview [class*="BottomSheetPopup-module__genralModalbody"] {
  max-height: 600px;
  min-height: 600px;
}

.webview [class*="SharedPopUp-module__genralModalbody"] {
  max-height: 600px;
  min-height: 600px;
}
.rpv-core__text-layer {
  display: none !important;
}
.rpv-core__page-layer {
  direction: ltr !important;
}

div#fc_frame {
  background-color: #ffffff;
}

.webview div[class*="botomNavContainer"],
.webview #LineCamp,
.webview .breadCrumb2,
.webview:not(.isTracking) [class*="siteHeader"] {
  display: none !important;
}

.webview .hasEvents:not(.applyWebVevent) {
  pointer-events: none !important;
}

.MainContainerLO.webview .notWebViewBtn {
  display: none !important;
}

.MainContainerLO:not(.webview) .webviewBTN {
  display: none !important;
}

.MainContainerLO:not(.webview) .showWebView {
  display: none !important;
}

.MainContainerLO.webview .hideWebView {
  display: none !important;
}

.MainContainerLO.webview .webViewDangerousHTMLContainer a {
  pointer-events: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* General styling (optional) */
input[type="number"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; /* Fallback for future-proofing */
}

.redBtn {
  background-color: #fe561c !important;
  transition: 0.2s;
  border: solid 1px #fe561c !important;
  box-sizing: border-box;
  color: #fff !important;
}

.redBtn:hover {
  background-color: #d65428 !important;
}
.redBtn:active {
  background-color: #d65428;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

span[class*="path"],
body[dir="ltr"] [class*=" icon-"],
body[dir="ltr"] [class*=" icon-"] *,
body[dir="ltr"] [class^="icon-"],
body[dir="ltr"] [class^="icon-"] * {
  font-family: icomoon !important;
}

img:not(.hasEvents) {
  pointer-events: none !important;
}

.greenTextBtn {
  color: #00b362;
  border: solid 1px #00b362;
  transition: 0.3s;
  box-sizing: border-box;
  cursor: pointer;
}

.greenTextBtn:hover {
  color: #ffffff;
  background-color: #00b362;
}

.videoWrapper {
  position: relative;
  padding-bottom: 34.1%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: 8px;
}

.videoWrapper iframe,
.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 8px;
}

.hide {
  display: none !important;
}

.disableEvents,
.disableEvents * {
  pointer-events: none !important;
  text-decoration: none !important;
}

@media screen and (min-width: 1025px) {
  .extraWideContainer {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
  .big {
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    padding: 0;
  }
  .big::after {
    width: 64px;
    height: 3px;
    left: calc(50% - 64px / 2);
    display: none;
  }
}

@media (max-width: 767px) {
    .h-mobile {
        display: none;
    }
}
@media (min-width: 768px) {
    .h-desktop {
        display: none;
    }
}
.errorText {
    color: #ec6234;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 4px;
    text-align: start;
}
.before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}
.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}
.after\:block::after {
  content: var(--tw-content);
  display: block;
}
.after\:h-full::after {
  content: var(--tw-content);
  height: 100%;
}
.after\:w-full::after {
  content: var(--tw-content);
  width: 100%;
}
.after\:bg-\[\#979797b3\]::after {
  content: var(--tw-content);
  background-color: #979797b3;
}
.hover\:border-transparent:hover {
  border-color: transparent;
}
.hover\:bg-danger-110:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 12 12 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-0:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}
.hover\:bg-primary-110:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(11 78 167 / var(--tw-bg-opacity, 1));
}
.hover\:bg-success-110:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 155 84 / var(--tw-bg-opacity, 1));
}
.hover\:bg-white-ghost:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 251 254 / var(--tw-bg-opacity, 1));
}
.hover\:text-\[\#484848\]:hover {
  --tw-text-opacity: 1;
  color: rgb(72 72 72 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-80:hover {
  --tw-text-opacity: 1;
  color: rgb(131 131 131 / var(--tw-text-opacity, 1));
}
.hover\:text-primary-110:hover {
  --tw-text-opacity: 1;
  color: rgb(11 78 167 / var(--tw-text-opacity, 1));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.hover\:underline:hover {
  text-decoration-line: underline;
}
.hover\:opacity-85:hover {
  opacity: 0.85;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}
.active\:bg-danger-120:active {
  --tw-bg-opacity: 1;
  background-color: rgb(179 9 9 / var(--tw-bg-opacity, 1));
}
.active\:bg-primary-120:active {
  --tw-bg-opacity: 1;
  background-color: rgb(7 65 142 / var(--tw-bg-opacity, 1));
}
.active\:bg-primary-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(212 222 250 / var(--tw-bg-opacity, 1));
}
.active\:bg-success-120:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 132 71 / var(--tw-bg-opacity, 1));
}
.active\:text-primary-120:active {
  --tw-text-opacity: 1;
  color: rgb(7 65 142 / var(--tw-text-opacity, 1));
}
.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}
.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}
.disabled\:border-neutral-60:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity, 1));
}
.disabled\:border-neutral-70:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.disabled\:bg-neutral-60:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity, 1));
}
.disabled\:text-neutral-70:disabled {
  --tw-text-opacity: 1;
  color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
.disabled\:opacity-50:disabled {
  opacity: 0.5;
}
.disabled\:hover\:border-neutral-70:hover:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.data-\[state\=checked\]\:translate-x-5[data-state="checked"] {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=unchecked\]\:translate-x-0[data-state="unchecked"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=\'checked\'\]\:border-primary[data-state='checked'] {
  --tw-border-opacity: 1;
  border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}
.data-\[state\=\'checked\'\]\:bg-primary[data-state='checked'] {
  --tw-bg-opacity: 1;
  background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.data-\[state\=unchecked\]\:bg-neutral-10[data-state="unchecked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 223 233 / var(--tw-bg-opacity, 1));
}
.data-\[state\=\'unchecked\'\]\:hover\:border-primary-110:hover[data-state='unchecked'] {
  --tw-border-opacity: 1;
  border-color: rgb(11 78 167 / var(--tw-border-opacity, 1));
}
.data-\[state\=\'unchecked\'\]\:hover\:bg-neutral-0:hover[data-state='unchecked'] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}
.data-\[state\=checked\]\:hover\:bg-primary-110:hover[data-state="checked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(11 78 167 / var(--tw-bg-opacity, 1));
}
.data-\[state\=unchecked\]\:hover\:bg-neutral-20:hover[data-state="unchecked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(172 184 200 / var(--tw-bg-opacity, 1));
}
.data-\[state\=checked\]\:disabled\:bg-neutral-70:disabled[data-state="checked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}
.data-\[state\=unchecked\]\:disabled\:bg-neutral-70:disabled[data-state="unchecked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}
.disabled\:data-\[state\=\'unchecked\'\]\:hover\:border-neutral-70:hover[data-state='unchecked']:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.data-\[state\=unchecked\]\:disabled\:hover\:bg-neutral-70:hover:disabled[data-state="unchecked"] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}
html[lang*="ar"] .ar\:left-3 {
  left: 0.75rem;
}
html[lang*="ar"] .ar\:left-\[5px\] {
  left: 5px;
}
html[lang*="ar"] .ar\:right-0 {
  right: 0px;
}
html[lang*="ar"] .ar\:right-\[5px\] {
  right: 5px;
}
html[lang*="ar"] .ar\:mr-auto {
  margin-right: auto;
}
html[lang*="ar"] .ar\:gap-4 {
  gap: 1rem;
}
html[lang*="ar"] .ar\:text-right {
  text-align: right;
}
html[lang*="ar"] .ar\:font-plex-arabic {
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
html[lang*="en"] .en\:left-0 {
  left: 0px;
}
html[lang*="en"] .en\:left-\[5px\] {
  left: 5px;
}
html[lang*="en"] .en\:right-3 {
  right: 0.75rem;
}
html[lang*="en"] .en\:right-\[5px\] {
  right: 5px;
}
html[lang*="en"] .en\:ml-auto {
  margin-left: auto;
}
html[lang*="en"] .en\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
html[lang*="en"] .en\:transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
html[lang*="en"] .en\:gap-2 {
  gap: 0.5rem;
}
html[lang*="en"] .en\:font-inter {
  font-family: "Inter", sans-serif;
}
@media (min-width: 640px) {
  .sm\:w-\[calc\(100\%\/4-0\.78em\)\] {
    width: calc(100% / 4 - 0.78em);
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .md\:right-\[50\%\] {
    right: 50%;
  }
  .md\:top-\[126px\] {
    top: 126px;
  }
  .md\:top-\[60px\] {
    top: 60px;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:\!mb-7 {
    margin-bottom: 1.75rem !important;
  }
  .md\:\!mt-\[72px\] {
    margin-top: 72px !important;
  }
  .md\:mb-0 {
    margin-bottom: 0px;
  }
  .md\:mb-8 {
    margin-bottom: 2rem;
  }
  .md\:mt-0 {
    margin-top: 0px;
  }
  .md\:mt-10 {
    margin-top: 2.5rem;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:mt-8 {
    margin-top: 2rem;
  }
  .md\:block {
    display: block;
  }
  .md\:hidden {
    display: none;
  }
  .md\:h-\[184px\] {
    height: 184px;
  }
  .md\:h-\[265px\] {
    height: 265px;
  }
  .md\:h-\[446px\] {
    height: 446px;
  }
  .md\:w-\[194px\] {
    width: 194px;
  }
  .md\:w-\[318px\] {
    width: 318px;
  }
  .md\:min-w-\[567px\] {
    min-width: 567px;
  }
  .md\:min-w-\[calc\(100\%\/4\)\] {
    min-width: calc(100% / 4);
  }
  .md\:\!max-w-\[1150px\] {
    max-width: 1150px !important;
  }
  .md\:max-w-\[735px\] {
    max-width: 735px;
  }
  .md\:max-w-\[767px\] {
    max-width: 767px;
  }
  .md\:max-w-\[calc\(100\%\/4\)\] {
    max-width: calc(100% / 4);
  }
  .md\:flex-\[20\%\] {
    flex: 20%;
  }
  .md\:flex-\[80\%\] {
    flex: 80%;
  }
  .md\:shrink-0 {
    flex-shrink: 0;
  }
  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .md\:transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .md\:scroll-mt-\[220px\] {
    scroll-margin-top: 220px;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:gap-6 {
    gap: 1.5rem;
  }
  .md\:overflow-x-visible {
    overflow-x: visible;
  }
  .md\:rounded-\[16px\] {
    border-radius: 16px;
  }
  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .md\:bg-gray-alice {
    --tw-bg-opacity: 1;
    background-color: rgb(243 247 251 / var(--tw-bg-opacity, 1));
  }
  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }
  .md\:p-4 {
    padding: 1rem;
  }
  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .md\:pb-8 {
    padding-bottom: 2rem;
  }
  .md\:pt-4 {
    padding-top: 1rem;
  }
  .md\:pt-6 {
    padding-top: 1.5rem;
  }
  .md\:text-center {
    text-align: center;
  }
  .md\:after\:fixed::after {
    content: var(--tw-content);
    position: fixed;
  }
  .md\:after\:left-0::after {
    content: var(--tw-content);
    left: 0px;
  }
  .md\:after\:top-0::after {
    content: var(--tw-content);
    top: 0px;
  }
  .md\:after\:z-\[-1\]::after {
    content: var(--tw-content);
    z-index: -1;
  }
  .md\:after\:block::after {
    content: var(--tw-content);
    display: block;
  }
  .md\:after\:h-full::after {
    content: var(--tw-content);
    height: 100%;
  }
  .md\:after\:w-full::after {
    content: var(--tw-content);
    width: 100%;
  }
  .md\:after\:bg-\[\#F4F7FB\;\]::after {
    content: var(--tw-content);
    background-color: #F4F7FB;;
  }
  .md\:after\:content-\[\'\'\]::after {
    --tw-content: '';
    content: var(--tw-content);
  }
}
@media (min-width: 1024px) {
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:-mb-\[20px\] {
    margin-bottom: -20px;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem;
  }
  .lg\:block {
    display: block;
  }
  .lg\:min-h-\[calc\(100vh-70px\)\] {
    min-height: calc(100vh - 70px);
  }
  .lg\:min-w-\[440px\] {
    min-width: 440px;
  }
  .lg\:max-w-\[740px\] {
    max-width: 740px;
  }
  .lg\:max-w-\[992px\] {
    max-width: 992px;
  }
  .lg\:justify-center {
    justify-content: center;
  }
  .lg\:gap-2 {
    gap: 0.5rem;
  }
  .lg\:gap-\[72px\] {
    gap: 72px;
  }
  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }
  .lg\:p-10 {
    padding: 2.5rem;
  }
  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .lg\:pt-20 {
    padding-top: 5rem;
  }
  .lg\:text-center {
    text-align: center;
  }
}
@media (min-width: 1280px) {
  .xl\:block {
    display: block;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:max-w-\[1130px\] {
    max-width: 1130px;
  }
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1536px) {
  .\32xl\:max-w-\[1330px\] {
    max-width: 1330px;
  }
}
.ltr\:rotate-180:where([dir="ltr"], [dir="ltr"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&_\.indicator\]\:data-\[disabled\]\:border-neutral-70[data-disabled] .indicator {
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.\[\&_\.indicator\]\:data-\[disabled\]\:text-neutral-70[data-disabled] .indicator {
  --tw-text-opacity: 1;
  color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:data-\[disabled\]\:hover\:border-neutral-70:hover[data-disabled][data-state="unchecked"] .indicator {
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:hover\:border-primary-110:hover[data-state="unchecked"] .indicator {
  --tw-border-opacity: 1;
  border-color: rgb(11 78 167 / var(--tw-border-opacity, 1));
}
.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:data-\[disabled\]\:hover\:bg-white:hover[data-disabled][data-state="unchecked"] .indicator {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:hover\:bg-neutral-0:hover[data-state="unchecked"] .indicator {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}
.\[\&_label\]\:cursor-pointer label {
  cursor: pointer;
}
.\[\&_label\]\:data-\[disabled\]\:cursor-not-allowed[data-disabled] label {
  cursor: not-allowed;
}
.\[\&_label\]\:data-\[disabled\]\:text-neutral-70[data-disabled] label {
  --tw-text-opacity: 1;
  color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
